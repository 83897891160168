import React, { forwardRef, useState } from 'react';
import Select from 'react-select';
import { Button } from '@design-system/button';
import { ExtendableImage } from '@design-system/extendable-image';
import { HeadingBlock } from '@design-system/heading-block';
import { PageBlock } from '@design-system/page-block';
import { Typography } from '@design-system/typography';
import TMPLinkWrap from '@husky-x/gatsby-theme-husky/src/components/TMPLinkWrap';
import { headingBlockMapper } from '@husky-x/gatsby-theme-husky/src/mappers/headingBlock';
import { HeadingBlock as HeadingBlockMapperProps } from '@husky-x/gatsby-theme-husky/src/mappers/headingBlock/models';
import { imageMapper } from '@husky-x/gatsby-theme-husky/src/mappers/image';
import { Image as ImageMapperProps } from '@husky-x/gatsby-theme-husky/src/mappers/image/models';
import { pageBlockUIMapper } from '@husky-x/gatsby-theme-husky/src/mappers/pageBlock';
import { PageBlockUI as PageBlockUIMapperProps } from '@husky-x/gatsby-theme-husky/src/mappers/pageBlock/models';

import { toCamelCase } from './util';

type SelectorBannerProps = {
  uid: string;
  componentId: string;
  pageBlockUi: { ui: PageBlockUIMapperProps }[];
  heading: HeadingBlockMapperProps;
  image: ImageMapperProps;
  imageIsIcon: boolean;
  iconImageIntroText: string;
  cta: { title: string };
  selectPlaceholder: string;
  selectOptions: { name: string; link: string }[];
};

export const SelectorBanner = forwardRef<HTMLDivElement, { modelData: unknown }>((props, ref) => {
  const {
    uid,
    componentId,
    pageBlockUi: [pageBlockUi],
    heading,
    image,
    imageIsIcon,
    iconImageIntroText,
    cta,
    selectPlaceholder,
    selectOptions,
  } = toCamelCase(props.modelData) as SelectorBannerProps;

  const [ctaLink, setCtaLink] = useState<string | undefined>();

  return (
    <PageBlock
      {...pageBlockUIMapper(pageBlockUi.ui)}
      className={`selector-banner selector-banner--${imageIsIcon ? 'full' : 'split'}`}
      componentUid={uid}
      ref={ref}
      id={componentId}
    >
      {!imageIsIcon ? (
        <div className="selector-banner__image">
          <ExtendableImage {...imageMapper(image)} />
        </div>
      ) : null}
      <div className="selector-banner__content">
        <HeadingBlock {...headingBlockMapper(heading)} className="selector-banner__heading" />
        <div className="selector-banner__select-cta-container">
          <div className="selector-banner__select-container">
            {imageIsIcon ? (
              <div className="selector-banner__subtitle">
                <Typography
                  content={iconImageIntroText}
                  variant="headline"
                  size="xxl"
                  className="selector-banner__subtitle-text"
                />
                <div className="selector-banner__subtitle-icon">
                  <ExtendableImage {...imageMapper(image)} />
                </div>
              </div>
            ) : null}
            <Select
              className="selector-banner__select"
              classNamePrefix="selector-banner__select"
              classNames={{
                container: (state) => (state.isFocused ? 'selector-banner__select--focused' : ''),
              }}
              placeholder={selectPlaceholder}
              options={selectOptions.map(({ name, link }) => ({ label: name, value: link }))}
              onChange={(item) => setCtaLink((item as { label: string; value: string }).value)}
              components={{ IndicatorSeparator: () => null }}
            />
          </div>
          {ctaLink ? (
            <TMPLinkWrap href={ctaLink} variant="primary" className="selector-banner__cta">
              {cta.title}
            </TMPLinkWrap>
          ) : (
            <Button disabled className="selector-banner__cta">
              {cta.title}
            </Button>
          )}
        </div>
      </div>
    </PageBlock>
  );
});
