import React from 'react';
import { CustomComponentsProvider } from '@husky-x/gatsby-theme-husky';

import { IngredientsBlock } from './src/components/IngretientsBlock';
import { Retailers } from './src/components/Retailers';
import { SelectorBanner } from './src/components/SelectorBanner';
import { Separator } from './src/components/Separator';

export const wrapRootElement = ({ element }) => (
  <CustomComponentsProvider
    list={[
      {
        key: 'separator',
        component: Separator,
      },
      {
        key: 'custom-ingredients-block',
        component: IngredientsBlock,
      },
      {
        key: 'custom-selector-banner',
        component: SelectorBanner,
      },
      {
        key: 'custom-retailers',
        component: Retailers,
      },
    ]}
  >
    {element}
  </CustomComponentsProvider>
);
